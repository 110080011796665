import * as React from 'react'

import {
	Button,
	Flex,
	Text,
	Heading,
	useToast,
	Container
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { updateRFID, validateShortCode } from '../../../../services/rfid'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import RfidCard from '../../../../icons/rfid-card.svg'
import { object, string } from 'yup'
import { getTempGroup, setRFID } from '../../../../helpers/database'
import { navigate } from 'gatsby'
import Input from '../../../../components/input'
import eventTracking from '../../../../services/eventTracking'
import QRScanner from '../../../../components/QRScanner'
import { errorToastOptions } from '../../../../helpers'
import { useLocation } from '@reach/router'
import BasePage from '../../../../components/basePage'
import { assignRfidToMember, getBooking } from '../../../../services/booking'
import { Textfit } from 'react-textfit'

const LinkRFIDPage = ({ bookingId, memberId }) => {
	const { state } = useLocation()
	const rfid = state?.rfid

	const { data: groupData } = useQuery({
		queryKey: ['booking', bookingId],
		queryFn: () => getBooking(bookingId),
		retry: false
	})

	const [validating, setValidate] = useState(false)
	const resolver = yupResolver(
		object()
			.shape({
				code: string().trim().required().min(4)
			})
			.required()
	)
	const {
		watch,
		register,
		handleSubmit,
		getValues,
		setError,
		clearErrors,
		setValue,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false
	})
	const watchCode = watch('code')
	const [editing, setEditing] = useState(false)

	const group = getTempGroup()
	const toast = useToast()

	const isValid = !!watchCode
	// const { fetchStatus, data, isFetching } = useQuery(
	// 	['code', watchCode?.trim().toUpperCase()],
	// 	validateShortCode,
	// 	{
	// 		enabled: validating && !!watchCode?.trim()
	// 	}
	// )

	const updateRFIDMutation = useMutation(updateRFID, {
		onSuccess: async (data) => {
			setEditing(false)
			await navigate(`/manage-boooking/${bookingId}/ready-to-play`)
		},
		onError: (error) => {
			setEditing(false)
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	const onValidateCode = async () => {
		const data = getValues()
		if (!data?.code) return

		onSubmit(data)

		// setValidate(true)
	}

	useEffect(() => {
		if (!group) {
		}
	}, [])

	const onNewScanResult = (decodedText) => {
		// handle decoded results here

		let code
		if (decodedText.toString().includes('http')) {
			code = decodedText.split('cardid=')[1]
		} else {
			code = decodedText
		}

		const cleanedId = code?.replace(/<|>/g, '')
		const splitId = cleanedId.split('')
		const segmentedId = []
		for (let i = 0; i < splitId.length; i += 2) {
			segmentedId.unshift(`${splitId[i]}${splitId[i + 1]}`)
		}
		const reversedId = segmentedId.join('')

		setValue('code', reversedId)
		onValidateCode()
	}

	const onSubmit = async (data) => {
		setRFID(data)
		setEditing(true)

		try {
			await assignRfidToMember(bookingId, memberId, data.code)
			setEditing(false)
			await navigate(`/manage-booking/${bookingId}/ready-to-play`)
		} catch (err) {
			setEditing(false)
			toast({
				description:
					'Oops! something went wrong assigning this rfid, please try again',
				...errorToastOptions
			})
		}

		// updateRFIDMutation.mutate({
		// 	groupId: bookingId,
		// 	rfid: data.cardId
		// })
	}

	// useEffect(() => {
	// 	if (isFetching) {
	// 		clearErrors('code')
	// 		setValidate(false)
	// 	} else {
	// 		if (!!data?.cardId) {
	// 			clearErrors('code')
	// 			setValidate(false)
	// 			onSubmit(data)
	// 		} else if (!!data) {
	// 			setValidate(false)
	// 			setError('code', {
	// 				type: 'custom',
	// 				message: 'Oh no! That code is invalid'
	// 			})
	// 			eventTracking('errors_RFID_read', {
	// 				// user_id: profile?.id,
	// 				RFID: watchCode
	// 			})
	// 		}
	// 	}
	// }, [data, fetchStatus, isFetching])

	if (!!bookingId && bookingId !== 'null') {
		setRFID({ bookingId })
		// return <SelectTeamPage />
	}

	return (
		<form onSubmit={handleSubmit(onValidateCode)}>
			<BasePage
				theme="dark"
				access={1}
				fullWidthActionBtns
				noHeader={false}
				hideSidebar={true}
				primaryBtn={
					<Button
						variant="primary"
						isLoading={editing}
						disabled={!isValid}
						type="submit"
					>
						Link
					</Button>
				}
				secondaryBtn={
					<Button
						width="full"
						mt="5"
						variant="link"
						onClick={() => navigate(`/manage-booking/${bookingId}`)}
					>
						Cancel
					</Button>
				}
			>
				{rfid ? (
					<Flex justifyContent="end">
						<Flex
							background="darkGray"
							borderRadius="full"
							alignItems="center"
							justifyContent="center"
							px={2}
							py={1}
							gap={1}
						>
							<StaticImage
								src="../../../../images/icon-ok.png"
								style={{
									height: 22,
									width: 22,
									objectFit: 'contain'
								}}
								alt="ok"
							/>
							<Text
								textColor="white"
								textTransform="uppercase"
								fontWeight={700}
							>
								{rfid}
							</Text>
						</Flex>
					</Flex>
				) : (
					<Flex
						background="darkGray"
						p={5}
						flexDir="column"
						alignItems="center"
						textColor="white"
					>
						<Text fontWeight={700} fontSize={18}>
							Congratulations, you've joined
						</Text>
						<Flex
							position="relative"
							h="fit-content"
							w="full"
							alignItems="center"
							justifyContent="center"
						>
							<StaticImage
								position="absolute"
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									bottom: 0,
									right: 0
								}}
								alt="Banner"
								src="../../../../images/board-grey.png"
							/>
							<Textfit
								mode="single"
								max={36}
								forceSingleModeWidth={true}
								style={{
									zIndex: 1,
									color: 'black',
									fontWeight: 800,
									padding: '12px 20px',
									width: '100%',
									textAlign: 'center'
								}}
							>
								{groupData?.group?.name}
							</Textfit>
						</Flex>
					</Flex>
				)}
				<Flex justifyContent="center" alignItems="center" gap={2}>
					<RfidCard width="80px" />
					<Heading as="h1" color={'white'} textAlign="left">
						{rfid ? (
							<>
								Link New
								<br />
								card
							</>
						) : (
							<>
								Now link
								<br />
								your card
							</>
						)}
					</Heading>
				</Flex>
				<Container maxWidth={'80%'} pt={'70%'} position={'relative'}>
					<Container
						position={'absolute'}
						top={0}
						left={0}
						height={'100%'}
						width={'100%'}
					>
						<QRScanner qrCodeSuccessCallback={onNewScanResult} />
					</Container>
				</Container>
				<Input
					name="code"
					type="code"
					variant="filled"
					placeholder="Games Card Code"
					mt="40px"
					register={register}
					errors={errors}
				/>
				<Text variant="m" mt="10px" color="white" textAlign="center">
					Tip: Find your RFID code below <br />
					the QR code on your card.
				</Text>
			</BasePage>
		</form>
	)
}

export default LinkRFIDPage
